<template>
  <div id="setImportList">
    <div class="goods-box" ref="goodsBox">
      <ul>
        <li class="goods-item" v-for="(item, index) in goodsList" :key="index">
          <img v-lazy="item.product_cover" alt />
          <p class="title">{{item.product_name}}{{item.specifications}} / {{item.unit}}</p>
          <div class="bot">
            <div>
<!--              <span>￥{{item.vip_price > 0 ? item.vip_price : item.original_price}}</span>-->
<!--              <del-->
<!--                class="origain"-->
<!--                v-if="!item.vip_price && (item.original_price == item.vip_price)"-->
<!--              >￥{{item.original_price}}</del>-->
              <span  class="">￥{{item.vip_price}}</span>
            </div>
            <i @click="handle(item)">设置</i>
          </div>
        </li>
        <p class="bottom-tips" v-if="empty">别划了，到底儿啦</p>
      </ul>
    </div>

    <div class="submit-box">
      <div class="submit-btn" @click="$router.push({path: '/'})">确认提交</div>
    </div>

    <van-popup v-model="show" position="bottom" :style="{ height: '226px' }">
      <p class="title">{{currentProduct.product_name}}</p>
      <div class="item">
        <span>商品状态(选填)：</span>
        <van-checkbox-group v-model="radio" direction="horizontal">
          <van-checkbox name="active" checked-color="#FF6737" shape="square">店长推荐</van-checkbox>
          <van-checkbox name="special" checked-color="#FF6737" shape="square">特价商品</van-checkbox>
        </van-checkbox-group>
      </div>

      <div class="item">
        <span>商品售价：</span>
        <van-field input-align="right" v-model="price" type="number" />
      </div>
      <div class="item">
        <span>优惠价（选填）：</span>
        <van-field input-align="right" v-model="vipPrice" type="number" />
      </div>
      <div class="bottom-btn">
        <div class="left-btn" @click="show = false">取消</div>
        <div class="right-btn" @click="submitEditBtn">确认修改</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import BScroll from "better-scroll";
import { submitEdit, importEditList } from "../api/requist";
import { Toast } from "vant";
export default {
  components: {},
  props: [],
  data() {
    return {
      product_id: "",
      goodsListScroller: null,
      vipPrice: "0.10",
      price: "0.10",
      radio: [],
      empty: false,
      pageCurrent: 1,
      pageSize: 10,
      show: false,
      goodsList: [],
      currentProduct: {},
    };
  },
  created() {
    this.product_id = this.$route.query.res;
  },
  mounted() {
    this.getList();
  },
  watch: {},
  computed: {},
  methods: {
    async getList() {
      if (this.empty) {
        return false;
      }

      if (this.goodsListScroller) {
        this.goodsListScroller.closePullUp();
      }
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      let formdata = new FormData();
      formdata.append("merch_id", localStorage.getItem("merch_id"));
      formdata.append("pageCurrent", this.pageCurrent);
      formdata.append("pageSize", this.pageSize);
      formdata.append("product_id", this.product_id);
      let res = await importEditList(formdata);
      if (res.code === 200) {
        if (this.goodsListScroller) {
          this.goodsListScroller.refresh();
        }
        this.goodsList = [...this.goodsList, ...res.data.ProductAtlasList];

        if (res.data.ProductAtlasList.length) {
          this.pageCurrent = ++this.pageCurrent;
        } else {
          this.empty = true;
        }

        if (this.goodsList.length < this.pageSize) {
          this.empty = true;
        }

        this.$nextTick(() => {
          if (!this.goodsListScroller) {
            this.goodsListScroller = new BScroll(this.$refs.goodsBox, {
              scrollY: true,
              scrollX: false,
              mouseWheel: true,
              bounce: {
                top: true,
                left: false,
                right: false,
                bottom: false,
              },
              click: true,
              taps: true,
              pullUpLoad: {
                threshold: 50,
              },
            });
            this.goodsListScroller.on("pullingUp", () => {
              this.getList();
              this.goodsListScroller.finishPullUp(); // 事情做完，需要调用此方法告诉 better-scroll 数据已加载，否则下拉事件只会执行一次
            });
          }
        });
        if (this.goodsListScroller) {
          this.goodsListScroller.openPullUp();
        }
        Toast.clear();
      }
    },
    handle(i) {
      this.currentProduct = i;
      this.price = this.currentProduct.original_price
        ? this.currentProduct.original_price
        : this.price;
      this.vipPrice = this.currentProduct.vip_price
        ? this.currentProduct.vip_price
        : this.vipPrice;
      if (this.currentProduct.is_recommend == 1) {
        this.radio.push("active");
      } else {
        this.radio.splice(this.radio.indexOf("active"), 1);
      }

      if (this.currentProduct.is_special_price == 1) {
        this.radio.push("special");
      } else {
        this.radio.splice(this.radio.indexOf("special"), 1);
      }

      this.show = true;
    },
    async submitEditBtn() {
      if (+this.vipPrice > +this.price) {
        return Toast("优惠价不可大于零售价");
      }

      if (+this.vipPrice <= 0) {
        return Toast("优惠价不可小于等于0元");
      }
      if (+this.price <= 0) {
        return Toast("零售价不可小于等于0元");
      }
      let formdata = new FormData();
      formdata.append("merch_id", localStorage.getItem("merch_id"));
      formdata.append("product_id", this.currentProduct.id);
      formdata.append(
        "is_special_price",
        this.radio.indexOf("special") == -1 ? 0 : 1
      );
      formdata.append(
        "is_recommend",
        this.radio.indexOf("active") == -1 ? 0 : 1
      );
      formdata.append("original_price", this.price);
      formdata.append("vip_price", this.vipPrice);
      let res = await submitEdit(formdata);
      if (res.code === 200) {
        this.show = false;
        this.goodsList.forEach((item) => {
          if (item.id == this.currentProduct.id) {
            item.is_special_price = this.radio.indexOf("special") == -1 ? 0 : 1;
            item.is_recommend = this.radio.indexOf("active") == -1 ? 0 : 1;
            item.original_price = this.price;
            item.vip_price = this.vipPrice;
          }
        });
        Toast("设置成功");
      }
    },
  },
};
</script>
<style lang="scss" >
#setImportList {
  box-sizing: border-box;
  background: rgba(245, 247, 250, 1);
  height: 100vh;
  .goods-box {
    height: calc(100vh - 52px);
    ul {
      box-sizing: border-box;
      padding: 12px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
    .goods-item {
      margin-bottom: 10px;
      padding: 8px 6px;
      box-sizing: border-box;
      width: calc((100vw - 12px * 2 - 11px) / 2);
      height: 248px;
      background: rgba(255, 255, 255, 1);
      border-radius: 4px;
      img {
        height: 158px;
        width: 158px;
        object-fit: cover;
      }

      .title {
        margin: 6px 0;
        height: 40px;
        font-size: 14px;
        text-align: left;
        line-height: 20px;
        color: rgba(48, 49, 51, 1);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .bot {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > div {
          display: flex;
          align-items: flex-end;
          span {
            font-size: 16px;
            color: rgba(255, 72, 72, 1);
            font-weight: bold;
          }
          .origain {
            font-size: 11px;
            color: rgba(192, 196, 204, 1);
          }
        }

        i {
          font-style: normal;
          width: 44px;
          height: 22px;
          background: rgba(255, 251, 249, 1);
          border: 1px solid rgba(255, 103, 55, 1);
          box-shadow: 0px 2px 4px rgba(255, 103, 55, 0.16);
          border-radius: 13px;
          line-height: 22px;
          color: rgba(255, 103, 55, 1);
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }
  .submit-box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 52px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px -3px 4px rgba(119, 118, 118, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    .submit-btn {
      width: 274px;
      height: 38px;
      background: rgba(255, 103, 55, 1);
      border-radius: 20px;
      line-height: 38px;
      text-align: center;
      font-size: 14px;
      color: rgba(255, 255, 255, 1);
    }
  }
  .bottom-tips {
    width: 100%;
    margin: 18px auto;
    font-size: 12px;
    color: rgba(192, 196, 204, 1);
    text-align: center;
  }
  .van-popup {
    padding: 20px 16px 0;
    box-sizing: border-box;
    border-radius: 9px 9px 0px 0px;
    .title {
      font-weight: bold;
      color: rgba(48, 49, 51, 1);
      font-size: 14px;
      text-align: center;
    }

    .item {
      display: flex;
      align-items: center;
      height: 36px;
      justify-content: space-between;
      span {
        display: block;
        white-space: nowrap;
        font-size: 14px;
        color: rgba(144, 147, 153, 1);
      }

      .van-checkbox__icon,
      .van-icon {
        height: 12px;
        width: 12px;
      }

      .van-icon::before {
        height: 12px;
        width: 12px;
      }
      .van-icon {
        font-size: 0.7em;
        line-height: 0.8;
      }
      .van-checkbox--horizontal:nth-child(1) {
        margin-right: 36px;
      }

      .van-checkbox--horizontal:nth-child(2) {
        margin-right: 0;
      }

      input {
        font-weight: bold;
        color: rgba(255, 72, 72, 1);
        font-size: 16px;
      }
    }

    .bottom-btn {
      margin-top: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left-btn {
        width: 166px;
        height: 38px;
        box-sizing: border-box;
        border: 1px solid rgba(255, 103, 55, 1);
        border-radius: 22px;
        font-size: 14px;
        line-height: 38px;
        text-align: center;
        color: rgba(255, 103, 55, 1);
      }

      .right-btn {
        width: 166px;
        height: 38px;
        background: rgba(255, 103, 55, 1);
        border-radius: 22px;
        font-size: 14px;
        text-align: center;
        line-height: 38px;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
</style>
